.super-flow__node {
  -webkit-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #b4b4b4;
  z-index: 1;
  outline: none;
}
.super-flow__node.can-move {
  cursor: move;
}
.super-flow__node-header {
  background-color: green;
}
.super-flow__node .node-side {
  position: absolute;
  cursor: crosshair;
}
.super-flow__node .node-side-top {
  top: -5px;
  right: 0;
  left: 0;
  height: 10px;
}
.super-flow__node .node-side-right {
  top: 0;
  right: -5px;
  bottom: 0;
  width: 10px;
}
.super-flow__node .node-side-bottom {
  right: 0;
  bottom: -5px;
  left: 0;
  height: 10px;
}
.super-flow__node .node-side-left {
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;
}
.super-flow__node:hover {
  -webkit-box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.4);
          box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.4);
}
.super-flow__node.isSelect {
  z-index: 2;
}
