.super-flow {
  font-family: Apple System, 'SF Pro SC', 'SF Pro Display', 'Helvetica Neue', Arial, 'PingFang SC', 'Hiragino Sans GB', STHeiti, 'Microsoft YaHei', 'Microsoft JhengHei', 'Source Han Sans SC', 'Noto Sans CJK SC', 'Source Han Sans CN', sans-serif;
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.super-flow > .select-all__mask {
  position: absolute;
  background-color: rgba(85, 175, 255, 0.1);
  z-index: 20;
  border: 1px dashed #55abfc;
  cursor: move;
  outline: none;
}
