.super-flow__menu-container .super-flow__menu {
  position: absolute;
  outline: none;
  width: 180px;
  padding: 4px 0;
  border: 1px solid #b4b4b4;
  -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border-radius: 3px;
  z-index: 10;
  background-color: #ffffff;
  margin: 0;
}
.super-flow__menu-container .super-flow__menu-item {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 170px;
  min-height: 26px;
  line-height: 26px;
  cursor: pointer;
  position: relative;
  padding: 0 4px;
}
.super-flow__menu-container .super-flow__menu-item:last-child {
  margin: 0;
}
.super-flow__menu-container .super-flow__menu-item:last-child:after {
  display: none;
}
.super-flow__menu-container .super-flow__menu-item:hover {
  background-color: #eeeeee;
}
.super-flow__menu-container .super-flow__menu-item-icon {
  float: left;
  width: 26px;
  height: 26px;
}
.super-flow__menu-container .super-flow__menu-item-content {
  float: left;
  display: inline-block;
  color: #333333;
  font-size: 14px;
  line-height: 26px;
  width: 144px;
  font-weight: normal;
}
.super-flow__menu-container .super-flow__menu-item > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.super-flow__menu-container .super-flow__menu-item.is-disabled {
  cursor: no-drop;
}
.super-flow__menu-container .super-flow__menu-item.is-disabled > span {
  color: #999999;
}
.super-flow__menu-container .super-flow__menu-item.is-disabled:hover {
  background-color: transparent;
}
.super-flow__menu-container .super-flow__menu-line {
  width: 100%;
  margin: 4px 0;
  border-bottom: 1px solid #b4b4b4;
  height: 0;
}
.super-flow__menu-container .super-flow__menu-line:last-child {
  display: none;
}
.super-flow__menu-container .flow__menu-mask {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background-color: transparent;
}
